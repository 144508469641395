@tailwind components;
@tailwind utilities;

.paperboat-logo img {
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}

.paperboat-logo:hover img {
    filter: grayscale(0%);
}

.ReactModalPortal .ril__inner .ril__image {
	max-height: unset;
}

.overlay-bg {
    @apply h-1/3 top-auto bottom-0 #{!important};
}